import * as React from 'react'
import classNames from 'classnames'
import { node, string } from 'prop-types'
import styles from './RichText.module.css'

const RichText = ({ children, className, ...rest }) => (
	<div {...rest} className={classNames(styles.content, className)}>
		{children}
	</div>
)

RichText.propTypes = {
	children: node,
	className: string
}

export default RichText
