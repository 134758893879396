import * as React from 'react'
import { string } from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import Layout from '@/container/Layout'
import Breadcrumb from '@/components/global/Breadcrumb'
import Loading from '@/components/screens/Loading'
import RichText from '@/utils/RichText'
import useFetchContent from '@/hooks/useFetchContent'
import Link from '@/utils/Link'
import Button from '@/components/buttons/Button'

const LOADING = 'LOADING'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'

function Legal({ pathname, type, url, title }) {
	const [view, setView] = React.useState(LOADING)
	const { content, status } = useFetchContent({
		type,
		url
	})
	const SHOW_LOADING = view === LOADING
	const SHOW_SUCCESS = view === SUCCESS
	const SHOW_ERROR = view === ERROR
	const { loggedIn, benefitBrandName } = useSelector(state => state.user)

	React.useEffect(() => {
		if (status === 'success' && content) {
			setView(SUCCESS)
		} else if (status === 'error') {
			setView(ERROR)
		}
	}, [status, content])

	return (
		<Layout>
			<Helmet>
				<title>{`${title} | ${benefitBrandName}`}</title>
			</Helmet>
			<div className="mb-lg">
				<Breadcrumb
					items={[
						{ href: loggedIn ? '/app/home/' : '/', text: 'Home' },
						{ href: pathname, text: title }
					]}
				/>
			</div>
			{SHOW_LOADING && <Loading>Loading content</Loading>}
			{SHOW_SUCCESS && (
				<div className="pb-2xl" data-testid="page">
					<RichText dangerouslySetInnerHTML={{ __html: content }} />
				</div>
			)}
			{SHOW_ERROR && (
				<div data-testid="page-error" className="flex items-center form-screen">
					<div className="mx-auto max-w-login py-xl">
						<div className="mb-lg lg:mb-xl">
							There was an issue requesting the latest {title}
						</div>
						<Button
							as={Link}
							to="/"
							className="flex-grow w-full mr-md px-xl md:w-auto md:px-2xl"
						>
							Return to home
						</Button>
					</div>
				</div>
			)}
		</Layout>
	)
}

Legal.propTypes = {
	pathname: string.isRequired,
	type: string.isRequired,
	url: string.isRequired,
	title: string.isRequired
}

export default Legal
