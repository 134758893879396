import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

function useFetchContent({ type, url }) {
	const store = useSelector(state => state.content)
	const dispatch = useDispatch()
	const content = store[type]

	React.useEffect(() => {
		async function fetchContent() {
			await dispatch({
				type: 'content/REQUEST_CONTENT',
				payload: { url, type }
			})
		}

		if (!content) {
			fetchContent()
		}
	}, [dispatch, content, url, type])

	return { content, status: store.status }
}

export default useFetchContent
