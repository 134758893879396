import * as React from 'react'
import Link from '@/utils/Link'
import { string, shape, arrayOf } from 'prop-types'
import classNames from 'classnames'
import Arrow from '@/icons/icon-breadcrumb-arrow.svg'
import { isNotLast } from '@/utils'
import IconWrapper from '@/utils/IconWrapper'
function Breadcrumb({ items }) {
	return (
		<ul className="flex items-center py-sm">
			{items.map(({ text, href }, index, { length }) => (
				<li
					className={classNames({ 'mr-md': isNotLast(index, length - 1) })}
					key={text}
				>
					<Link
						className={classNames('flex items-center text-sm', {
							'text-grey': !isNotLast(index, length - 1),
							'text-primary': isNotLast(index, length - 1)
						})}
						to={href}
					>
						{text}
						{isNotLast(index, length - 1) && (
							<IconWrapper
								icon={Arrow}
								wrapperClassName="inline-flex items-center w-full text-primary ml-md"
								style={{ width: 6, height: 9 }}
							/>
						)}
					</Link>
				</li>
			))}
		</ul>
	)
}

Breadcrumb.propTypes = {
	items: arrayOf(
		shape({
			text: string.isRequired,
			href: string.isRequired
		})
	).isRequired
}

export default Breadcrumb
